import React, { useState } from "react";
import logo from "../../assets/images/Headerlogo.svg";
import { Link } from "react-router-dom";
import Banner from "../Banner";
import Button from "../Button";

export const Header = ({ selectedBanner, onHandleClick }) => {
  const [isShow, setShow] = useState(false);
  const fontColor2 = '#ffff'

  return (
    <>
      <div
        className={`${
          selectedBanner !== "home" ? "header-banner vh-50" : "header-banner"
        } `}
      >
        <div className="header p-0 p-lg-2">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand" to="/">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShow(!isShow)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse custom-nav ${
                isShow ? "show" : ""
              }`}
            >
              <div className="row g-0 justify-content-center w-100 h-100">
                <div className={`col-12 col-lg-10`}>
                  <ul className="navbar-nav me-auto mb-2 justify-content-center mb-lg-0 align-items-center">
                    <li
                      className="nav-item"
                      onClick={() => {
                        onHandleClick("home");
                        setShow(!isShow);
                      }}
                    >
                      <Link
                        className={`nav-link fs-6 fw-normal ${
                          selectedBanner === "home" ? "active" : ""
                        }`}
                        to="/"
                        style={{fontSize: '15px', fontWeight: "lighter", fontFamily: "sans-serif", letterSpacing: '1px'}}
                      >
                        Home
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        onHandleClick("investor");
                        setShow(!isShow);
                      }}
                    >
                      <Link
                        className={`nav-link ${
                          selectedBanner === "investor" ? "active" : ""
                        }`}
                        to="/investor"
                        style={{fontSize: '15px', fontWeight: "revert", fontFamily: "sans-serif", letterSpacing: '1px'}}
                      >
                        Roadmap
                      </Link>
                    </li>
                    <li
                      class="nav-item"
                      onClick={() => {
                        onHandleClick("technology");
                        setShow(!isShow);
                      }}
                    >
                      <Link
                        className={`nav-link ${
                          selectedBanner === "technology" ? "active" : ""
                        }`}
                        to="technology"
                        style={{fontSize: '15px', fontWeight: "lighter", fontFamily: "sans-serif", letterSpacing: '1px'}}
                      >
                        Technology
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        onHandleClick("whoweare");
                        setShow(!isShow);
                      }}
                    >
                      <Link
                        className={`nav-link ${
                          selectedBanner === "whoweare" ? "active" : ""
                        }`}
                        to="whoweare"
                        style={{fontSize: '15px', fontWeight: "lighter", fontFamily: "sans-serif", letterSpacing: '1px'}}
                      >
                        Who We Are
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        onHandleClick("contactus");
                        setShow(!isShow);
                      }}
                    >
                      <Link
                        className={`nav-link ${
                          selectedBanner === "contactus" ? "active" : ""
                        }`}
                        style={{fontSize: '15px', fontWeight: "lighter", fontFamily: "sans-serif", letterSpacing: '1px'}}
                        to="contactus"
                      >
                        Contact us
                      </Link>
                    </li>
                   
                  </ul>
                 
                </div>
                {/* <div className={`col-12 col-lg-2`}>
                  <a
                    className="text-decoration-none"
                    href="https://fulfillment.trumarket.tech/"
                  >
                    <div className="fullfilment d-flex justify-content-center align-items-center align-content-center">
                      Fulfillment
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
              <a href="https://fulfillment.trumarket.tech" className={"button-marketplace"}
                >
              <span>Marketplace</span>
              </a>
          </nav>
        </div>
        <Banner selectedBanner={selectedBanner} />
      </div>
    </>
  );
};

export default Header;
